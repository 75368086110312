import { useEffect, useState } from 'react'
import { Container, BarActions, BoxOptions, BoxActions, Option, Action, ContentsAlert, Box, BoxPreview, Tela, TelaContents, TelaPreview, Space, BoxImg, ContainerPreview, PreviewTitle, PreviewBox, Voltar, BoxCards, Proximo, PreviewPaginate, BoxProductStore, BoxInforProductStore, NameProduct, PriceProduct, BoxPurchase, QtdPurchase, ButtonPurchase, ButtonFavorite } from './styled'
import { AiFillGift, AiFillHeart, AiFillStar } from "react-icons/ai";
import { RiQuestionnaireFill, RiUserLine } from "react-icons/ri";
import { useSelector } from 'react-redux';
import AlertClose from '../../../../components/Alert-Close';
import imgBackground from "../../../../assets/imgs/preview-background.png";
import imgPreviewTela from "../../../../assets/imgs/preview-tela.svg";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import imgBasePreviewTela from "../../../../assets/imgs/base-preview.svg";

import imgPreviewLeft from "../../../../assets/imgs/preview-left.svg";
import imgPreviewRight from "../../../../assets/imgs/preview-right.svg";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BarMenuStore, ContainerStore, ListStoreMenu } from '../preview-icon-box/styled';
import { BsCart3 } from 'react-icons/bs';

import IconBolsa from "../../../../assets/imgs/icon-bolsa.svg";
import { getTranslation } from '../../../../translations';

const PreviewButton = () => {

  const config = useSelector(state => state.reducerControlConfig)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  return (
    <BoxPreview>
      <Container img={imgBackground} font={config.font} >
        <Box backgroundColor={config.background_color_primary}>
          {/* <img src={imgPreviewTela} /> */}
          <Tela>
            <TelaContents>
              <TelaPreview>
                <BarMenuStore>
                  <label>{translation.thema.preview.suaLoja}</label>
                  <span>
                    <label>Buscar</label>
                  </span>
                  <ListStoreMenu animation={false}>
                    {/* <div>
                      <AiFillHeart color={"#fff"} size={20} />
                      <label>Favoritos</label>
                    </div> */}
                    <div>
                      <RiUserLine color={"#fff"} size={20} />
                      <label>{translation.thema.preview.minhaConta}</label>
                    </div>
                    <div>
                      <BsCart3 color={"#fff"} size={20} />
                      <label>{translation.thema.preview.meuCarrinho}</label>
                    </div>
                  </ListStoreMenu>
                </BarMenuStore>
                <ContainerStore>
                  <BoxProductStore>
                    <img src={IconBolsa} />
                  </BoxProductStore>
                  <BoxInforProductStore>
                    <NameProduct>{translation.thema.preview.bolsa}</NameProduct>
                    <PriceProduct>R$ 99,00</PriceProduct>
                    <BoxPurchase>
                      <QtdPurchase>
                        - 1 +
                      </QtdPurchase>
                      <ButtonPurchase>Comprar</ButtonPurchase>
                    </BoxPurchase>
                    <ButtonFavorite
                      align={config.widget_button_text_align}
                      color={config.widget_button_text_color}
                      underline={config.widget_button_text_underline}
                      italic={config.widget_button_text_italic}
                      bolt={config.widget_button_text_bolt}
                      size={config.widget_button_text_font_size}
                      radius={config.widget_button_border_radius}
                      background={config.widget_button_color_background}
                    >
                      {config.widget_button_icon == "heart" ?
                        <AiFillHeart color={config.widget_button_color_icon} size={20} /> :
                        <AiFillGift color={config.widget_button_color_icon} size={20} />}

                      <label>{config.widget_button_text}</label>
                    </ButtonFavorite>
                  </BoxInforProductStore>
                </ContainerStore>
              </TelaPreview>
            </TelaContents>
            <Space />
            <BoxImg>
              <img width={200} src={imgBasePreviewTela} />
            </BoxImg>
          </Tela>
        </Box>
      </Container>

    </BoxPreview >

  )
}

export default PreviewButton