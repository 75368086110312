import { useEffect, useState } from 'react'
import { Container, BarActions, BoxOptions, BoxActions, Option, Action, ContentsAlert, Box, BoxPreview, Tela, TelaContents, TelaPreview, Space, BoxImg, ContainerPreview, PreviewTitle, PreviewBox, Voltar, BoxCards, Proximo, PreviewPaginate, BoxProductStore, BoxInforProductStore, NameProduct, PriceProduct, BoxPurchase, QtdPurchase, ButtonPurchase, ButtonFavorite, ArrowBox, SwiperBox, CardItemProduct, ImgBox, NameBox, PriceBox, ContainerStore, ContainerStoreNew, TelaPreviewNew } from './styled'
import { AiFillGift, AiFillHeart, AiFillStar, AiOutlineGift, AiOutlineHeart } from "react-icons/ai";
import { RiQuestionnaireFill, RiUserLine } from "react-icons/ri";
import { useSelector } from 'react-redux';
import AlertClose from '../../../../components/Alert-Close';
import imgBackground from "../../../../assets/imgs/preview-background.png";
import imgPreviewTela from "../../../../assets/imgs/preview-tela.svg";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import imgBasePreviewTela from "../../../../assets/imgs/base-preview.svg";

import imgPreviewLeft from "../../../../assets/imgs/preview-left.svg";
import imgPreviewRight from "../../../../assets/imgs/preview-right.svg";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { BarMenuStore, ListStoreMenu } from '../preview-icon-box/styled';
import { BsCart3 } from 'react-icons/bs';

import IconBolsa from "../../../../assets/imgs/icon-bolsa.svg";
import { getTranslation } from '../../../../translations';
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

const PreviewIcon = () => {

  const config = useSelector(state => state.reducerControlConfig)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  const getPositionX = () => {
    switch (config.icon_position) {
      case "left-top":
        return { left: "0px", top: "0px" }
      case "center-top":
        return { left: "80px", top: "0px" }
      case "right-top":
        return { right: "0px", top: "0px" }

      case "left-center":
        return { left: "0px", top: "55px" }
      case "center-center":
        return { left: "80px", top: "55px" }
      case "right-center":
        return { right: "0px", top: "55px" }

      case "left-bottom":
        return { left: "0px", bottom: "0px" }
      case "center-bottom":
        return { left: "80px", bottom: "0px" }
      case "right-bottom":
        return { right: "0px", bottom: "0px" }

      default:
        break;
    }
  }

  const getPositionY = () => {

  }

  return (
    <BoxPreview>
      <Container img={imgBackground} font={config.font} >
        <Box backgroundColor={config.background_color_primary}>
          {/* <img src={imgPreviewTela} /> */}
          <Tela>
            <TelaContents>
              <TelaPreviewNew>
                {/* <BarMenuStore>
                  <label>{translation.thema.preview.suaLoja}</label>
                  <span>
                    <label>Buscar</label>
                  </span>
                  <ListStoreMenu animation={false}>
                    <div>
                      <RiUserLine color={"#fff"} size={20} />
                      <label>{translation.thema.preview.minhaConta}</label>
                    </div>
                    <div>
                      <BsCart3 color={"#fff"} size={20} />
                      <label>{translation.thema.preview.meuCarrinho}</label>
                    </div>
                  </ListStoreMenu>
                </BarMenuStore> */}
                <ContainerStoreNew>
                  <ArrowBox>
                    <span>
                      <MdArrowBackIos />
                    </span>
                  </ArrowBox>

                  <SwiperBox>
                    <CardItemProduct>
                      <ImgBox position>
                        <img src={IconBolsa} />
                        <span style={getPositionX()}>
                          {config.icon_type == "heart" ?
                            <AiFillHeart color={config.icon_color} size={24}  /> :
                            <AiFillGift color={config.icon_color} size={24} />}
                        </span>
                      </ImgBox>
                      <NameBox>
                        Bolsa Couro Importada
                      </NameBox>
                      <PriceBox>
                        <p>R$ 199,00</p>
                        <label><strong>10</strong> x de <strong>R$ 19,90</strong></label>
                      </PriceBox>
                    </CardItemProduct>

                    <CardItemProduct>
                      <ImgBox>
                        <img src={IconBolsa} />
                        <span style={getPositionX()}>
                          {config.icon_type == "heart" ?
                            <AiOutlineHeart color={config.icon_color} size={24} /> :
                            <AiOutlineGift color={config.icon_color} size={24} />}
                        </span>
                      </ImgBox>
                      <NameBox>
                        Bolsa Couro Nacional
                      </NameBox>
                      <PriceBox>
                        <p>R$ 99,00</p>
                        <label><strong>10</strong> x de <strong>R$ 9,90</strong></label>
                      </PriceBox>
                    </CardItemProduct>
                  </SwiperBox>

                  <ArrowBox>
                    <span>
                      <MdArrowForwardIos />
                    </span>
                  </ArrowBox>
                </ContainerStoreNew>
              </TelaPreviewNew>
            </TelaContents>
            <Space />
            <BoxImg>
              <img width={200} src={imgBasePreviewTela} />
            </BoxImg>
          </Tela>
        </Box>
      </Container>

    </BoxPreview >

  )
}

export default PreviewIcon